
<template>
  <v-main>
       <Header></Header>
          <v-row>
                <v-col>
                    <div class="aurora2">

        <v-img max-height="30em" contain min-height="20em" :src="require('../assets/Resized Pictures/_DSC4777.webp')">
         
         </v-img> 

             </div>
                </v-col>
            </v-row>
         <v-divider></v-divider>
             <v-container>
       <!-- <div class="space"> -->
      <v-row >
        <v-col>
         
           <v-card-title  primary>
              <h1>
                  Our Services
              </h1> 
            </v-card-title>
          <div class="space">
            <v-container>
              <h3>
                The Code Effect is counted among the best emerging software solution companies in Sudan. We provide a wide range of services personalized and tailored as per the specific demands of our customers. Every project needs to load fast, be pixel-perfect and user-friendly. Consistency and high execution quality are based on our unique processes.
                </h3> 
             </v-container>
            </div>
        </v-col>
       </v-row>
     </v-container>
     <div class="section3">

       <SCards></SCards>
     </div>
         <v-divider></v-divider>
       <Footer></Footer>
  </v-main>  
</template>


<script>

import SCards from '../components/SCards'
import Header from '../components/Header'
import Footer from '../components/Footer'


export default {
  name: 'Portfolio',
  components: {
    SCards,
    Header,
    Footer
    
  },
    
}
</script>